:root [data-theme="builtcode"] {
  --main-color: $mainColor;
}

[data-theme="builtcode"] .ch-account-content-menu>.ch-dropdown-menu>.ch-dropdown-menu-item.active>.ch-dropdown-menu-item-label:before,
[data-theme="builtcode"] .ch-menu-item.active a:before {
  border-right-color: #0BE390;
}

[data-theme="builtcode"] .ch-account-content-menu>.ch-dropdown-menu>.ch-dropdown-menu-item.active>.ch-dropdown-menu-item-label>.fa-icon:first-child,
[data-theme="builtcode"] .ch-menu-item.active a>.fa-icon:first-child {
  color: #0BE390;
}

[data-theme="builtcode"] .ch-menu-item.ch-dropdown.open>.ch-dropdown-button>.fa-icon:first-child {
  color: #0BE390;
}

[data-theme="builtcode"] .ch-pagination.ui.pagination.menu .item.active {
  border-bottom-color: #0BE390;
}

@media only screen and (max-width: 768px) {
  [data-theme="builtcode"] .ch-nav:not(.active) .ch-menu-item.active a:before {
    border-bottom-color: #0BE390;
  }
}

[data-theme="builtcode"] .ch-loader-progress:before {
  background-color: #0BE390;
}

[data-theme="builtcode"] .ch-menu-item.active>a {
  color: #0BE390;
}




[data-theme="builtcode"] a:hover span.ch-logo-label {
  text-decoration: none;
  color: #0BE390 !important;
}

[data-theme="builtcode"] a:hover span.ch-logo-label {
  text-decoration: none;
  color: #0BE390 !important;
}

[data-theme="builtcode"] .ch-nav:not(.collapsed)>.ch-logo>.ch-logo-icon  {
  width: 200px;
  height: 54px;
}

[data-theme="builtcode"] .ch-nav.collapsed.hover>.ch-logo>.ch-logo-icon {
  width: 200px;
  height: 54px;
}

[data-theme="builtcode"] .ch-nav:not(.collapsed)>.ch-logo>.ch-logo-icon>img {
  max-width: 200px;
  max-height: 54px;
  content: url('assets/images/ch-logo.png');
}

[data-theme="builtcode"] .ch-nav.collapsed.hover>.ch-logo>.ch-logo-icon>img {
  max-width: 200px;
  max-height: 54px;
  content: url('assets/images/ch-logo.png');
}

[data-theme="builtcode"] .ch-nav.collapsed>.ch-logo>.ch-logo-icon {
  width: 54px;
  height: 54px;
}

[data-theme="builtcode"] .ch-nav.collapsed>.ch-logo>.ch-logo-icon>img {
  max-width: 54px;
  max-height: 54px;
  margin-right: 15px;
  content: url('assets/images/ch-logo-brand.png');
}
