@import "~@ng-select/ng-select/themes/default.theme.css";

a {
  cursor: pointer;
}
body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  background: #fff;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 14px;
  color: rgba(0,0,0,.87);
  font-smoothing: antialiased;
}


#toast-container>div {
  opacity: 1;
}
.main-content{
  width:100%;
}
.p-treetable .p-treetable-header{
  height: 100px !important;
}

.p-treetable .p-treetable-tbody > tr > td{
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0 0.3rem !important;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
width: 2rem;
height: 1rem !important;
color: #6c757d;
border: 0 none;
background: transparent;
border-radius: 50%;
transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
margin-right: 0.5rem;
}

.p-treeselect {
display: flex !important;
-webkit-user-select: none;
user-select: none;
}

.p-datatable-scrollable .p-frozen-column {
position: sticky;
background: inherit;
}

.m-r-5 {
margin-right: 5px !important;
}

.m-r-10 {
margin-right: 10px !important;
}

.m-r-15 {
margin-right: 15px !important;
}

.m-r-20 {
margin-right: 20px !important;
}

.m-r-25 {
margin-right: 25px !important;
}

.m-r-30 {
margin-right: 30px !important;
}

.m-r-35 {
margin-right: 35px !important;
}

.m-r-40 {
margin-right: 40px !important;
}

.m-r-45 {
margin-right: 45px !important;
}

.m-r-50 {
margin-right: 50px !important;
}

.m-r-100 {
margin-right: 100px !important;
}

.m-l-5 {
margin-left: 5px !important;
}

.m-l-10 {
margin-left: 10px !important;
}

.m-l-15 {
margin-left: 15px !important;
}

.m-l-20 {
margin-left: 20px !important;
}

.m-l-25 {
margin-left: 25px !important;
}

.m-l-30 {
margin-left: 30px !important;
}

.m-l-35 {
margin-left: 35px !important;
}

.m-l-40 {
margin-left: 40px !important;
}

.m-l-45 {
margin-left: 45px !important;
}

.m-l-50 {
margin-left: 50px !important;
}

.m-l-100 {
margin-left: 100px !important;
}

.m-b-5 {
margin-bottom: 5px !important;
}

.m-b-10 {
margin-bottom: 10px !important;
}

.m-b-15 {
margin-bottom: 15px !important;
}

.m-b-20 {
margin-bottom: 20px !important;
}

.m-b-25 {
margin-bottom: 25px !important;
}

.m-b-30 {
margin-bottom: 30px !important;
}

.m-b-35 {
margin-bottom: 35px !important;
}

.m-b-40 {
margin-bottom: 40px !important;
}

.m-b-45 {
margin-bottom: 45px !important;
}

.m-b-50 {
margin-bottom: 50px !important;
}

.m-b-100 {
margin-bottom: 100px !important;
}

.m-t-5 {
margin-top: 5px !important;
}

.m-t-10 {
margin-top: 10px !important;
}

.m-t-15 {
margin-top: 15px !important;
}

.m-t-20 {
margin-top: 20px !important;
}

.m-t-25 {
margin-top: 25px !important;
}

.m-t-30 {
margin-top: 30px !important;
}

.m-t-35 {
margin-top: 35px !important;
}

.m-t-40 {
margin-top: 40px !important;
}

.m-t-45 {
margin-top: 45px !important;
}

.m-t-50 {
margin-top: 50px !important;
}

.m-t-100 {
margin-top: 100px !important;
}

.p-r-5 {
padding-right: 5px !important;
}

.p-r-10 {
padding-right: 10px !important;
}

.p-r-15 {
padding-right: 15px !important;
}

.p-r-20 {
padding-right: 20px !important;
}

.p-r-25 {
padding-right: 25px !important;
}

.p-r-30 {
padding-right: 30px !important;
}

.p-r-35 {
padding-right: 35px !important;
}

.p-r-40 {
padding-right: 40px !important;
}

.p-r-45 {
padding-right: 45px !important;
}

.p-r-50 {
padding-right: 50px !important;
}

.p-r-100 {
padding-right: 100px !important;
}

.p-l-5 {
padding-left: 5px !important;
}

.p-l-10 {
padding-left: 10px !important;
}

.p-l-15 {
padding-left: 15px !important;
}

.p-l-20 {
padding-left: 20px !important;
}

.p-l-25 {
padding-left: 25px !important;
}

.p-l-30 {
padding-left: 30px !important;
}

.p-l-35 {
padding-left: 35px !important;
}

.p-l-40 {
padding-left: 40px !important;
}

.p-l-45 {
padding-left: 45px !important;
}

.p-l-50 {
padding-left: 50px !important;
}

.p-l-100 {
padding-left: 100px !important;
}

.p-b-5 {
padding-bottom: 5px !important;
}

.p-b-10 {
padding-bottom: 10px !important;
}

.p-b-15 {
padding-bottom: 15px !important;
}

.p-b-20 {
padding-bottom: 20px !important;
}

.p-b-25 {
padding-bottom: 25px !important;
}

.p-b-30 {
padding-bottom: 30px !important;
}

.p-b-35 {
padding-bottom: 35px !important;
}

.p-b-40 {
padding-bottom: 40px !important;
}

.p-b-45 {
padding-bottom: 45px !important;
}

.p-b-50 {
padding-bottom: 50px !important;
}

.p-b-100 {
padding-bottom: 100px !important;
}

.p-t-5 {
padding-top: 5px !important;
}

.p-t-10 {
padding-top: 10px !important;
}

.p-t-15 {
padding-top: 15px !important;
}

.p-t-20 {
padding-top: 20px !important;
}

.p-t-25 {
padding-top: 25px !important;
}

.p-t-30 {
padding-top: 30px !important;
}

.p-t-35 {
padding-top: 35px !important;
}

.p-t-40 {
padding-top: 40px !important;
}

.p-t-45 {
padding-top: 45px !important;
}

.p-t-50 {
padding-top: 50px !important;
}

.p-t-100 {
padding-top: 100px !important;
}

.ui.stacked.segment::after{
border-top: none !important;
}

.ui.sortable.table thead th:after {
display: none;
font-style: normal;
font-weight: 400;
text-decoration: inherit;
content: '';
height: 1em;
width: auto;
opacity: .8;
margin: 0 0 0 .5em;
font-family: Icons;
}

.ui.sortable.table thead th.sorted:after {
display: inline-block;
}
.ui.sortable.table thead th.ascending:after {
content: '\f0d8';
}
.ui.sortable.table thead th.sorted:after {
display: inline-block;
}
.ui.sortable.table thead th.descending:after {
content: '\f0d7';
}

.nowrap{
width: 1%;
white-space: nowrap;
}

.div-td, .div-th {
display: table-cell;

position: -webkit-sticky;
position: sticky;
top: 0px;
z-index: 2;
font-weight: bold;
min-width: 125px;
background: #fff;
border: none;

text-align: center;
vertical-align: middle;

border-top: none;
border-left: none;
box-shadow: none;
margin-right: 5px;
}

.div-table {
display: table;
}

.div-td, .div-th {
display: table-cell;
}

.div-thead {
display: table-header-group;
}

.div-tbody {
display: table-row-group;
}

.div-tr {
display: table-row;
}

.horizontal.scrollable {
overflow-x: scroll;
}

.tabela-fluxo-consolidado {
width: 100%;
border-collapse: collapse;
}

.tabela-fluxo-consolidado .div-th {
position: -webkit-sticky;
position: sticky;
top: 0px;
z-index: 2;
font-weight: bold;
min-width: 125px;
background: #fff;
border: none;
/* box-shadow: 0 0 0 1px #dededf; */
}

.tabela-fluxo-consolidado .div-td:nth-child(1) {
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  left: 1px;
  z-index: 1;
  /* background: #fff; */
  /* border: none; */
  /* box-shadow: 0 0 0 1px #dededf; */
}

.tabela-fluxo-consolidado .div-th, .tabela-fluxo-consolidado .div-td {
  /* height: 28px; */
  /* border: 2px solid #dededf; */
  /* background: #fff; */
  text-align: center;
  vertical-align: middle;
}


.tabela-fluxo-consolidado .div-td.movable:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
/* .div-tbody .div-tr-list.cdk-drop-list-dragging .div-td:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
} */

.celula-container {
  height: 100%;
  padding: 4px;
  display: flex;
  flex-direction: row;
}

.celula-container .valor-container {
  order: 1;
  align-content: center;
  justify-content: flex-end;
  text-align: right;
  width: 100px;
}

.typeable input {
  background-color: #feffef;
  margin: 0;
  padding: 0;
  width: 90%;
  outline: none;
  border: none;
}

/* .celula-container .icones {
  order: 0;
  align-items: top;
  width: 25px;
  padding-right: 5px;
} */

.tabela-fluxo-consolidado .div-thead .div-th:first-child {
  border-top: none;
  border-left: none;
  box-shadow: none;
}

.has-comments::after {
  content: '';
  height: 16px;
  width: 16px;
  position: relative;
  text-align: right;
  margin-top: -19px;
  float: right;
  background: url('/assets/images/ui/td-comment-marker-primary.png' ) no-repeat top right;
}

.div-td.active {
  outline: 1px solid green;
}

.div-td {
  word-break: keep-all;
  white-space: nowrap;
}

.td-category {
  padding: 0 5px;
}

.group {
  font-weight: bold;
}

.text.red {
  color: #ff3333;
}

.text.blue {
  color: #2185d0 !important;
}

.group.with-children-groups {
  text-transform: uppercase;
}

.p-treetable-toggler  {
  margin-left: 0 !important;
  }

tr.striped.ng-star-inserted:nth-child(even) {
    background-color: rgb(250, 250, 250);
  }
